.App {
  text-align: center;
  background-color: #000220;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.play-btn {
  width: 140px;
  height: 56px;
  font-size: 23px;
  font-weight: 400;
  font-family: "Roboto";
  font-family: "Roboto Mono";
  font-family: "Press Start 2P";
}

.play-link {
  width: 140px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #eeeeee; */
  /* color: #000440; */
  color: #eeeeee;
  text-decoration: unset;
  border-radius: 4px;
  font-size: 23px;
  font-weight: 400;
  font-family: "Roboto";
  font-family: "Roboto Mono";
  font-family: "Press Start 2P";
}

.play-link:hover {
  color: #c72c90;
}

.App-logo {
  width: 100%;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */
